<template>
  <ValidationObserver @submit="createNewAlert">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModel"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Reminder for {{ label }}</span>
      </template>
      <template v-slot>
        <div class="flex flex-wrap pt-4">
          <div class="flex w-full flex-wrap px-3 mt-3">
            <div class="md:w-1/2 w-full px-2">
              <UiSingleSelect
                v-model="notificationData.role"
                :select-object-without-reduce-key="true"
                title="REMINDER_TO"
                rules="required"
                label="name"
                :options="options"
                :disabled="isUpperLevelTrigger || !!edit"
                :allow-html-tool-tip-for-long-text="true"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </div>

            <div
              v-if="notificationData.role && notificationData.role.except"
              class="md:w-1/2 w-full px-2 md:mb-0 mb-5"
            >
              <UiMultiSelect
                v-model="notificationData.except"
                :dynamic-title="true"
                :title="`Except ${notificationData.role.except}`"
                rules="required"
                :already-selected="alreadyExcluded"
                label="full_name"
                :options="usersList"
                :search-through-api="true"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                :class="$style.widthItems"
                @search="_getRoledata"
              />
            </div>
            <div v-else-if="notificationData.role" class="md:w-1/2 w-full px-2">
              <UiSingleSelect
                v-model="notificationData.user"
                :dynamic-title="true"
                :title="`Select ${notificationData.role.name}`"
                rules="required"
                reduce="id"
                label="full_name"
                :options="usersList"
                :search-through-api="true"
                :disabled="isUpperLevelTrigger"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                @search="_getRoledata"
              />
            </div>
          </div>
          <div class="border-t border-primary-grey w-full mx-5 mb-4"></div>
          <p class="text-xl px-5">Send Reminders through external mediums</p>
          <div class="flex w-full flex-wrap px-3 mt-5">
            <div class="md:w-1/2 w-full px-2">
              <UiSingleSelect
                :key="`priority1${forceRenderPriority}`"
                v-model="prioirities.priority1"
                title="MEDIUM_1"
                :rules="isRequired ? 'required' : ''"
                :label="'name'"
                :options="mediumList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                :disabled="isUpperLevelTrigger"
                @receiveList="itemSelected"
              />
            </div>
            <div v-if="prioirities && prioirities.priority1" class="md:w-1/2 w-full px-2">
              <UiSingleSelect
                :key="`priority2${forceRenderPriority}`"
                v-model="prioirities.priority2"
                title="MEDIUM_2"
                :label="'name'"
                :options="mediumList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                :disabled="isUpperLevelTrigger"
                @receiveList="itemSelected"
              />
            </div>
          </div>
          <div class="pl-5 text-xl mt-1 w-full pb-2">Send to:</div>
          <div class="mb-5 px-3 flex mx-2 flex-wrap sm:pl-3">
            <span class="text-sm mt-1">First medium</span>
            <div class="px-4 pt-0.5">
              <ToggleButton
                v-model="toggleValue"
                class="mt-1"
                checkbox-color-class="bg-dark-orange"
                :is-status-hide="true"
                :disabled="isUpperLevelTrigger"
                :custom-style="{ backgroundColor: 'var(--primary-purple-600)' }"
              />
            </div>
            <span class="mt-1 text-sm">All mediums</span>
          </div>
          <div class="border-t border-primary-grey w-full mx-5 mb-4"></div>
          <div class="flex flex-wrap w-full px-3 sm:pl-3 mb-10 mx-2">
            <span class="mt-1 text-sm">Send In App notification</span>
            <div class="px-7 pt-0.5">
              <ToggleButton
                v-model="toggleInAppNotificationValue"
                class="mt-1"
                :is-status-hide="true"
                :disabled="isUpperLevelTrigger"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <span v-if="modalButtonLoading">
              <Loader />
            </span>
            <span v-else>Save</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import NOTIFICATION_PATHS from '@src/constants/notification-constants.js'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import { Form as ValidationObserver } from 'vee-validate'

import * as GENERAL_CONSTANTS from '@src/constants/general-constants'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { buildWhereQuery } from '@/src/utils/filters'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  name: 'CreateEditFeePublishAlert',
  components: {
    UiModalContainer,
    Loader,
    ValidationObserver,
    UiSingleSelect,
    ToggleButton,
    UiMultiSelect,
    UIButton,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: { type: Array, default: () => GENERAL_CONSTANTS.ROLES_LIST },
    edit: { type: Object, default: () => null },
    featureAndActions: { type: Object, default: () => ({}) },
    module: {
      type: String,
      default: '',
    },
  },

  emits: ['handleClick', 'addNotificationAlert', 'update'],
  data() {
    return {
      notifications: 'notifications',
      dashboard: 'dashboard',
      modalButtonLoading: false,
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.INSTANT_NOTIFICATION,
      },
      forceRender: 0,
      prioirities: {},
      GENERAL_CONSTANTS,
      toggleValue: true,
      toggleInAppNotificationValue: false,
      usersList: [],
      mediumList: GENERAL_CONSTANTS.MEDIUM_LIST,
      defaultMediumList: GENERAL_CONSTANTS.MEDIUM_LIST,
      forceRenderPriority: 0,
      alreadyExcluded: [],
      notificationData: {
        role: '',
      },
      isUpperLevelTrigger: false,
    }
  },
  computed: {
    isRequired() {
      let returnVal = true
      if (this.toggleInAppNotificationValue) {
        returnVal = false
      }
      return returnVal
    },
    ...mapState({
      campusId: (state) => state.layout.currentCampusScope.id,
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
    }),
  },
  watch: {
    'notificationData.role': {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.alreadyExcluded = []
        }
        this.notificationData.except = []
        switch (newVal.getUser) {
          case 'teacher': {
            this._getTeacherData()
            break
          }
          case 'pickup person': {
            this._getPickupPersonData()
            break
          }
          case 'staff': {
            this._getStaffData()
            break
          }
          case 'student': {
            this._getStudentsData()
            break
          }
          case 'guardian': {
            this._getGaurdianData()
            break
          }
        }
      },
      deep: true,
    },

    edit: {
      handler(val) {
        if (val) {
          this.notificationData.role = this.options.filter((opt) => {
            return opt.value === val.send_to
          })[0]
          this.notificationData.user = val.receiver?.id

          /* Setting Prioriteies */
          val.mediums.forEach((medium, ind) => {
            this.prioirities['priority' + (ind + 1)] = this.mediumList.filter(
              (single_medium) => single_medium.name == medium,
            )[0]
          })
          this.itemSelected()
          /* IN APPNOTIFICATION ENABLED */
          this.toggleInAppNotificationValue = val.in_app_notification_enabled
          /* All Priorities */
          this.toggleValue = val.send_all_mediums ? val.send_all_mediums : false
          if (val.excluded_receivers && val.excluded_receivers.length) {
            this.alreadyExcluded = val.excluded_receivers
            this.forceRender++
          }
          this.isUpperLevelTrigger = this.edit.is_upper_level
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    _getRoledata(query = '') {
      switch (this.notificationData?.role?.receiverRoleId) {
        case 'section_teacher': {
          this._getTeacherData(query)
          break
        }
        case 'pickup person': {
          this._getPickupPersonData(query)
          break
        }
        case 'staff': {
          this._getStaffData(query)
          break
        }
        case 'section_student': {
          this._getStudentsData(query)
          break
        }
        case 'guardian': {
          this._getGaurdianData(query)
          break
        }
      }
    },
    itemSelected(id) {
      /* IN PROCESS */
      if (this.prioirities && !this.prioirities.priority1) {
        this.prioirities.priority2 = ''
      }
      let selectedMediumsList = []
      if (this.prioirities?.priority1) selectedMediumsList.push(this.prioirities?.priority1)
      if (this.prioirities?.priority2) selectedMediumsList.push(this.prioirities?.priority2)
      this.mediumList = this.defaultMediumList.filter((val) => {
        const isNotPresent = !selectedMediumsList.some((priority) => priority.id === val.id)
        return isNotPresent
      })
      this.notificationData.mediums = selectedMediumsList
    },

    handleClick() {
      this.$emit('handleClick')
    },

    async createNewAlert() {
      if (this.modalButtonLoading) return
      const data = {
        feature: this.featureAndActions.feature,
        action: this.featureAndActions.action,
        send_to: this.notificationData.role.value,
        excluded_receivers: this.notificationData.except,
        first_priority_medium: this.prioirities?.priority1?.name || '',
        second_priority_medium: this.prioirities?.priority2?.name || '',
        in_app_notification_enabled: this.toggleInAppNotificationValue,
        receiver_role_id: this.notificationData.role.receiverRoleId,
        send_all_mediums: this.toggleValue,
        receiver_id: this.notificationData.user,
      }
      if (this.module) this.$emit('addNotificationAlert', data)
      else this.defaultNotifications(data)
    },

    async defaultNotifications(data) {
      let res, err
      if (this.edit) {
        data.id = this.edit.id
        this.dataPayload.payload = data
        ;[res, err] = await this.updateNotificationTrigger(this.dataPayload)
      } else {
        this.dataPayload.payload = data
        ;[res, err] = await this.createNotificationTrigger(this.dataPayload)
      }
      if (res) {
        this.$emit('update', this.featureAndActions, {}, true)
        this.$emit('handleClick')
      }
    },

    async _getTeacherData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }

      let { data } = await this.getTeacherWithFilters(payload)
      this.usersList = data?.records
      this.usersList.forEach((teacher) => {
        teacher.receiver_user_id = teacher.id
      })

      this.forceRender++
    },
    async _getPickupPersonData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      const res = await this.getPickupMembers(payload)
      this.usersList = res.records.map((user) => {
        user.receiver_user_id = user.id
        return user
      })
      this.forceRender++
    },

    async _getStudentsData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      let data = {
        campus_id: this.campusId,
        role_id: 'section_student',
        section_id: this.currentSectionScope,
      }

      data = { ...data, ...payload }
      const res = await this.loadClassStudents(data)
      this.usersList = res.records.map((std) => {
        std.full_name = `${std.first_name} ${std.last_name}`
        std.receiver_user_id = std.id
        return std
      })
      this.forceRender++
    },

    async _getStaffData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      const res = await this.getStaffWithFilters(payload)
      this.usersList = res.data.records.map((user) => {
        user.receiver_user_id = user.id
        return user
      })
      this.forceRender++
    },

    async _getGaurdianData(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      let data = {
        campus_id: this.campusId,
        role_id: 'guardian',
      }
      data = { ...data, ...payload }
      const res = await this.loadClassStudents(data)
      this.usersList = res.records.map((guardian) => {
        guardian.full_name = `${guardian.first_name} ${guardian.last_name}`
        guardian.receiver_user_id = guardian.id
        return guardian
      })
      this.forceRender++
    },

    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('teacher', ['getTeacherWithFilters']),
    ...mapActions('student', ['loadClassStudents']),
    ...mapActions('notifications', ['createNotificationTrigger', 'updateNotificationTrigger']),
    ...mapActions('pickup', ['getPickupMembers']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
