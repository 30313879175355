const NOTIFICATION_PATHS = {
  TIME_TABLE: 'time_table_notification_triggers',
  INSTANT_NOTIFICATION: 'instant_notification_triggers',
  FEE: 'fee_notification_triggers',
  PICKUP: 'pickup_notification_triggers',
  CAMPUS_ATTENDANCE: 'campus_attendance_notification_triggers',
  PERIOD_ATTENDANCE: 'period_attendance_notification_triggers',
  SECTION_ATTENDANCE: 'section_attendance_notification_triggers',
  CLASS: 'class_notification_triggers',
  SECTION: 'section_notification_triggers',
  PICKUP_PERSON: 'pickup_person_notification_triggers',
  STAFF: 'staff_notification_triggers',
  STUDENT: 'student_notification_triggers',
  TEACHER: 'section_teacher_notification_triggers',
  EXAM_PLANNER: 'exam_planner_notification_triggers',
  LESSON_PLANNER: 'lesson_planner_notification_triggers',
  SYLLABUS_MANAGER: 'syllabus_manager_notification_triggers',
  SYLLABUS_AUTOMATOR: 'syllabus_automator_notification_triggers',
}
export default NOTIFICATION_PATHS

export const NOTIFCATIONS_OPTIONS = [
  {
    id: '1',
    name: 'Concerned Student',
    value: 'concerned_student',
    receiverRoleId: 'section_student',
    concern: true,
    except: 'these concerned student',
    getUser: 'student',
  },
  {
    id: '2',
    name: 'Concerned Students',
    value: 'concerned_students',
    receiverRoleId: 'section_student',
    except: 'Concerned Students',
    concern: true,
    getUser: 'student',
  },
  {
    id: '3',
    name: 'All Students',
    value: 'all_students',
    receiverRoleId: 'section_student',
    concern: true,
    except: 'these students',
    getUser: 'student',
  },
  {
    id: '4',
    name: 'Concerned Student Main Guardian',
    value: 'concerned_student_main_guardian',
    receiverRoleId: 'guardian',
    except: 'Guardians of these Students',
    concern: true,
    getUser: 'student',
  },
  {
    id: '5',
    name: 'Concerned Students Main Guardians',
    value: 'concerned_students_main_guardians',
    receiverRoleId: 'guardian',
    except: 'Main Guardians of these Students',
    concern: true,
    getUser: 'student',
  },
  {
    id: '6',
    name: 'All Main Guardians',
    value: 'all_main_guardians',
    receiverRoleId: 'guardian',
    concern: true,
    except: 'these guardians',
    getUser: 'student',
  },
  {
    id: '7',
    name: 'Concerned student Guardians',
    value: 'concerned_student_guardians',
    receiverRoleId: 'guardian',
    except: 'Main Guardian of these Student',
    concern: true,
    getUser: 'student',
  },

  {
    id: '8',
    name: 'Concerned students Guardians',
    value: 'concerned_students_guardians',
    receiverRoleId: 'guardian',
    except: 'Guardians of these Student',
    concern: true,
    getUser: 'guardian',
  },
  {
    id: '9',
    name: 'All Guardians',
    value: 'all_guardians',
    receiverRoleId: 'guardian',
    concern: true,
    except: 'these guardians',
    getUser: 'student',
  },
  {
    id: '10',
    name: 'Specific Staff',
    receiverRoleId: 'staff',
    value: 'specific_staff',
    getUser: 'staff',
  },
  {
    id: '11',
    name: 'Concerned Teacher',
    value: 'concerned_teacher',
    receiverRoleId: 'section_teacher',
    concern: true,
    except: 'these teacher',
    getUser: 'teacher',
  },

  {
    id: '12',
    name: 'Specific Teacher',
    receiverRoleId: 'section_teacher',
    value: 'specific_teacher',
    getUser: 'teacher',
  },
  {
    id: '13',
    name: 'All Teachers',
    value: 'all_teachers',
    receiverRoleId: 'section_teacher',
    concern: true,
    except: 'these teachers',
    getUser: 'teacher',
  },
  {
    id: '14',
    name: 'Concerned Teachers',
    value: 'concerned_teachers',
    receiverRoleId: 'section_teacher',
    concern: true,
    except: 'these teacher',
    getUser: 'teacher',
  },
  {
    id: '15',
    name: 'Concerned Pickup Person',
    value: 'concerned_pickup_person',
    receiverRoleId: 'pickup_person',
    concern: true,
    except: 'these pickup person',
    getUser: 'pickup person',
  },
]

export const NOTIFICATION_RECIEVERS = {
  SPECIFIC_STAFF: 'specific_staff',
  ALL_TEACHERS: 'all_teachers',
  SPECIFIC_TEACHER: 'specific_teacher',
  CONCERNED_STUDENTS: 'concerned_students',
  CONCERNED_STUDENT: 'concerned_student',
  CONCERNED_PICKUP_PERSON: 'concerned_pickup_person',
  CONCERNED_TEACHER: 'concerned_teacher',
  CONCERNED_STUDENT_MAIN_GUARDIAN: 'concerned_student_main_guardian',
  CONCERNED_STUDENTS_MAIN_GUARDIANS: 'concerned_students_main_guardians',
  CONCERNED_STUDENTS_GUARDIANS: 'concerned_students_guardians',
  CONCERNED_STUDENT_GUARDIANS: 'concerned_student_guardians',
}
